/**
 * @function: 앱으로 회원의 로그인, 회원가입 정보를 전송하는 함수
 * @description: 로그인일 경우 userId, token은 null isSignUp은 false로 전송, 회원가입은 isSignUp:true와 함께 userId, token을 전송
 * */
export function connectSignBridge(userId = null, token = null, isSignUp = false) {
    const os = getOs();
    if (os === 'android') {
      window.loginBridge.signInViaApp(
        JSON.stringify({
          userId,
          token,
          isSignUp
        })
      );
    } else if (os === 'ios') {
      window.webkit.messageHandlers.signInViaApp.postMessage({
        userId,
        token,
        isSignUp
      });
    }
}


export function getOs(){
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    if ( varUA.indexOf('android') > -1) {
        return "android";
    } else if ( varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1 || varUA.indexOf("ios") > -1 ) {
        return "ios";
    } else {
        return "other";
    }
}
