import { Contains } from "@modules/utils/Contains";
import Highlighter from "react-highlight-words";
import { useTranslation } from "next-i18next";
import MakeIcon from '@components/layout/SearchHeader/FilterBoxIcon';
export default function FilterBoxCard({
  type,
  searchData,
  dataList,
  handler
}) {
  // 다국어
  const {
    t
  } = useTranslation("common");
  return <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full">
      <div className={`flex justify-start items-center self-stretch
                   flex-grow-0 flex-shrink-0 relative gap-3 py-2.5 px-5 
                   SB_14_100_Medium text-Gray-700 'text-Gray-500 tracking-[0.2%] font-bold`}>
        {type === Contains.REGION && t('search.txt.city.search')}
        {type === Contains.HOTEL && t('search.txt.hotel.search')}
      </div>
      {dataList.items.map((info, index) => (type === Contains.REGION && index < 5 || type === Contains.HOTEL) && <div key={index} id={type === Contains.REGION ? "searching_region" : "searching_hotel"} className="flex justify-start items-center self-stretch
                     flex-grow-0 flex-shrink-0 relative gap-3 bg-white px-6 py-2 hover:bg-Bg-OffWhite
                     cursor-pointer tablet:gap-2 tablet:h-[54px] tablet:px-4" onClick={() => {
      handler(`${info.id}`, info.country ? `${info.name}, ${info.country}` : `${info.name}`, type, info.name);
    }}>
            {MakeIcon(type, info.type)}
            <div className="flex grow flex-col overflow-hidden">
              <p className="SB_14_100_Bold tablet:B_16_100_Bold leading-tight overflow-hidden text-ellipsis whitespace-nowrap text-Gray-900">
                <Highlighter highlightClassName={`text-PositiveBlue bg-white`} searchWords={searchData.searchText.split("")} autoEscape={true} textToHighlight={info.name || ""} />
              </p>
              <span className="mt-1 C_12_100_Regular text-Gray-600">
                  <Highlighter highlightClassName={`text-PositiveBlue bg-white`} searchWords={searchData.searchText.split("")} autoEscape={true} textToHighlight={info.country || ""} />
                </span>
            </div>
          {type === Contains.REGION && <span className={`SB_14_100_Regular text-Gray-600  w-[116px] flex justify-end`}>
              {" "}
                {t('search.txt.city.cnt.search', {
          n: info.count
        })}
            </span>}
        </div>)}
    </div>;
}