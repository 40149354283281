import IcLocateTTBBFi from "/public/images/ttbb/ic_locate_fi_g90.svg";
import { useTranslation } from "next-i18next";
import MakeIcon from '@components/layout/SearchHeader/FilterBoxIcon';
import { Contains } from '@modules/utils/Contains';
import { IC_TYPE } from '@constants/common';
export default function FilterBoxRecommend({
  searchKeywords,
  handler
}) {
  const {
    t
  } = useTranslation("common");
  return <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-full">
      <div className={`flex justify-start items-center self-stretch
                   flex-grow-0 flex-shrink-0 relative gap-3 py-2.5 px-5 tablet:px-4 
                   SB_14_100_Medium tablet:SB_14_100_Medium tablet:text-Gray-500 tablet:tracking-[0.2%] tablet:font-bold`}>
          {searchKeywords && searchKeywords?.length > 0 ? t("searchbar.txt.trip.recommend") : ''}
      </div>
      {searchKeywords?.map((item, index) => <div key={index} id={IC_TYPE.HOTEL.indexOf(item.type) > -1 ? "searching_recommand_keyword" : "searching_recommand_region"} className="flex justify-start items-center self-stretch
                     flex-grow-0 flex-shrink-0 relative gap-3 py-2 px-6 hover:bg-Bg-OffWhite tablet:px-4 bg-white
                     cursor-pointer tablet:gap-[10px] tablet:h-[54px]" onClick={() => {
      handler(`${item.region_id}`, item["country"] ? `${item["keyword"]}, ${item["country"]}` : `${item["keyword"]}`, '', item["keyword"]);
    }}>
          {MakeIcon(Contains.REGION, item.type)}
          <div className="flex grow flex-col overflow-hidden">
            <p className={`flex-grow text-Gray-900 ${IC_TYPE.RECOMMEND.indexOf(item.type) > -1 ? "SB_14_100_Bold tablet:P_15_160_Bold" : "SB_14_100_Regular tablet:B_16_100_Medium"}`}>
              {item["keyword"]}
            </p>
            {IC_TYPE.RECOMMEND.indexOf(item.type) === -1 && <p className="C_12_100_Regular mt-1 text-Gray-500 tablet:text-Gray-600">
                {item["country"]}
              </p>}
          </div>
        </div>)}
    </div>;
}